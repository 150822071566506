<template>
  <form @submit="validateForm.onSubmit" class="pt-2">
    <div class="relative">
      <div
        class="logical-operator__container"
        style="top: 0px; height: calc(100% - 20px)"
        v-if="conditionBlocks.length > 1"
      >
        <!-- <div class="logical-operator__text" :style="isAndOperator(_logicalOperator) ? 'left:-25px' : ''">
          {{ _logicalOperator }}
        </div> -->

        <el-select
          v-model="_logicalOperator"
          class="logical-operator__text"
          size="small"
          :style="isAndOperator(_logicalOperator) ? 'left:-25px' : ''"
        >
          <el-option key="And" label="AND" :value="operators.and" />
          <el-option key="Or" label="OR" :value="operators.or" />
        </el-select>
      </div>
      <div class="ml-9">
        <div
          v-for="(block, blockIdx) in conditionBlocks"
          :key="blockIdx"
          class="flex items-start justify-start relative"
        >
          <div class="flex justify-start relative w-full">
            <div
              class="rounded-md w-full mb-5 ml-2 mr-8 px-2 py-6 border-solid border border-gray-300 whitespace-nowrap align-middle relative bg-gray-color"
            >
              <div class="relative search-form">
                <div class="absolute logical-operator__container" v-if="block.searchConditions.length > 1">
                  <!-- <div
                    class="absolute logical-operator__text"
                    :style="isAndOperator(block.logicalOperator) ? 'left:-25px' : ''"
                  >
                    {{ block.logicalOperator }}
                  </div> -->

                  <el-select
                    v-model="block.logicalOperator"
                    class="logical-operator__text"
                    size="small"
                    :style="isAndOperator(block.logicalOperator) ? 'left:-25px' : ''"
                  >
                    <el-option key="And" label="AND" :value="operators.and" />
                    <el-option key="Or" label="OR" :value="operators.or" />
                  </el-select>
                </div>
                <div class="ml-10 w-full">
                  <SearchItem
                    v-for="(item, conditionIdx) in block?.searchConditions"
                    v-model:searchItem="block.searchConditions[conditionIdx]"
                    :key="item.key"
                    :index="conditionIdx"
                    :projectCustomFields="projectCustomFields(blockIdx)"
                    :isRemoveButton="block.searchConditions.length !== 1"
                    :isAddButton="conditionIdx === block.searchConditions.length - 1"
                    @remove:searchItem="removeItem(blockIdx, conditionIdx)"
                    @add:searchItem="addItem(blockIdx)"
                    @change:barcodeType="onUpdateSearchConditions(blockIdx)"
                  />
                </div>
              </div>
              <div v-if="block.searchConditions.length > 1" class="mt-2 ml-6">
                <!-- <el-radio-grou up> -->
              </div>
              <div class="w-full flex justify-center bottom-search-block absolute -bottom-5">
                <el-button
                  v-if="conditionBlocks.length > 1"
                  @click="removeSearchConditionBlock(blockIdx)"
                  type="info"
                  icon="el-icon-minus"
                  size="small"
                  circle
                  class="mx-2"
                />
                <el-button
                  v-if="blockIdx === conditionBlocks.length - 1"
                  @click="addSearchConditionBlock"
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  circle
                  class="mx-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- @change="updateSearchConditions" -->
    <!-- <div v-if="conditionBlocks.length > 1" class="mt-4 ml-8">
      <el-radio-group v-model="_logicalOperator">
        <el-radio :label="operators.and">{{ $t('barcode.all_conditions_are_met') }}</el-radio>
        <el-radio :label="operators.or">{{ $t('barcode.any_condition_is_met') }}</el-radio>
      </el-radio-group>
    </div> -->
  </form>
</template>

<script lang="ts">
import SearchItem from '@/components/barcodeSearch/SearchItem.vue'
import { BARCODE_SEARCH_KEYS, CUSTOM_FIELD_KEY, LOGICAL_OPERATOR_VALUE } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  IBarcodeDefinitionType,
  ICustomFieldData,
  ISearchConditionBlock,
  TArrProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { SearchItem },
  emits: ['update:searchConditionBlocks', 'update:logicalOperator'],
  name: 'SearchForm',
})
export default class SearchForm extends Vue {
  @PropSync('searchConditionBlocks', { type: Object }) _searchConditionBlocks!: ISearchConditionBlock[]
  @PropSync('logicalOperator', { type: String }) _logicalOperator!: string
  @Prop({ type: Number }) readonly version!: number

  created() {
    this.conditionBlocks = [...this._searchConditionBlocks.map((b) => ({ ...b }))]
  }

  // Temporary fix when server return 'And' 'Or' instead correct value 'and' 'or'
  get operators() {
    return this._logicalOperator === 'And' || this._logicalOperator === 'Or'
      ? { and: 'And', or: 'Or' }
      : { and: 'and', or: 'or' }
  }

  DEFAULT_CONDITION_BLOCK = {
    searchConditions: [
      {
        key: '',
        valueType: '',
      },
    ],
    logicalOperator: LOGICAL_OPERATOR_VALUE.and,
  }

  conditionBlocks: ISearchConditionBlock[] = [cloneDeep(this.DEFAULT_CONDITION_BLOCK)]

  @Watch('_searchConditionBlocks', { deep: true })
  onModelConditionBlocksChanged() {
    if (!isEqual(this.conditionBlocks, this._searchConditionBlocks)) {
      this.conditionBlocks = [...this._searchConditionBlocks.map((b) => cloneDeep(b))]
    }
  }

  @Watch('conditionBlocks', { deep: true })
  onConditionBlocksChanged() {
    if (!isEqual(this.conditionBlocks, this._searchConditionBlocks)) {
      this.$emit('update:searchConditionBlocks', cloneDeep(this.conditionBlocks))
    }
  }

  isAndOperator(logicalOperatorValue: string | undefined) {
    return logicalOperatorValue === LOGICAL_OPERATOR_VALUE.and
  }

  projectCustomFields(blockIdx: number): TArrProjectCustomField[] {
    if (this.version === 0) return []

    const projectBarcodeTypes = (this.$store.state.barcode.projectList.barcodeTypes ||
      this.$store.state.barcode.projectDetail.barcodeTypes ||
      {}) as Record<string, IBarcodeDefinitionType>

    const currentBlock = this.conditionBlocks[blockIdx]

    const searchConditionBCTypeKeys = currentBlock.searchConditions.reduce(
      (total, i) => (i.key === 'barcodeType' && i.value ? [...total, i.value] : total),
      [] as string[]
    )

    const availableBcTypes = Object.entries(projectBarcodeTypes).filter((item) => {
      if ((searchConditionBCTypeKeys ?? []).length !== 0 && this.isAndOperator(currentBlock.logicalOperator)) {
        return searchConditionBCTypeKeys.includes(item[0])
      }
      return true
    })

    return (availableBcTypes || []).reduce(
      (customFields, bcType) => [
        ...customFields,
        ...this.getSearchCustomFields(bcType[0], bcType[1].activationFields.customFields),
      ],
      [] as TArrProjectCustomField[]
    )
  }

  addSearchConditionBlock() {
    this.conditionBlocks.push(cloneDeep(this.DEFAULT_CONDITION_BLOCK))
  }

  removeSearchConditionBlock(idx: number) {
    this.conditionBlocks.splice(idx, 1)
  }

  getSearchCustomFields(bcTypeKey: string, data?: Record<string, ICustomFieldData>): TArrProjectCustomField[] {
    const customFieldSearchKeys = [
      CUSTOM_FIELD_KEY.text,
      CUSTOM_FIELD_KEY.number,
      CUSTOM_FIELD_KEY.singleSelect,
      CUSTOM_FIELD_KEY.multiSelect,
      CUSTOM_FIELD_KEY.date,
      CUSTOM_FIELD_KEY.email,
      CUSTOM_FIELD_KEY.phoneNumber,
    ]
    const result = [] as TArrProjectCustomField[]
    for (const key in data) {
      if (customFieldSearchKeys.includes(data[key]?.fieldType)) {
        result.push({ ...data[key], customFieldKey: key, bcTypeKey } as TArrProjectCustomField)
      }
    }
    return result
  }

  removeItem(blockIdx: number, conditionIdx: number) {
    const block = this.conditionBlocks[blockIdx]
    const hasLogicalOperator = (block.searchConditions ?? []).length > 1
    if (hasLogicalOperator) {
      block.searchConditions.splice(conditionIdx, 1)
      if (block.searchConditions.length === 1) block.logicalOperator = LOGICAL_OPERATOR_VALUE.and
    }
  }

  addItem(blockIdx: number) {
    this.conditionBlocks[blockIdx].searchConditions.push({ key: '', valueType: '' })
  }

  onUpdateSearchConditions(blockIdx: number) {
    const block = this.conditionBlocks[blockIdx]
    const hasBCTypeField = !!block.searchConditions.map((i) => i.key).find((i) => i === 'barcodeType')
    if (this.isAndOperator(block.logicalOperator) && hasBCTypeField) {
      const availableCFs = this.projectCustomFields(blockIdx).map((i) => i.customFieldKey)
      for (let i = (block.searchConditions ?? []).length - 1; i >= 0; i--) {
        if (
          block.searchConditions[i].key === BARCODE_SEARCH_KEYS.customField &&
          !availableCFs.includes(block.searchConditions[i].subKey ?? '')
        ) {
          block.searchConditions.splice(i, 1)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';
.logical-operator {
  &__container {
    position: absolute;
    z-index: 0;
    width: 15px;
    height: calc(100% - 10px);
    top: 5px;
    left: 10px;
    border: 1px solid #999;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__text:deep() {
    text-align: center;
    position: absolute;
    z-index: 1;
    width: 50px;
    // height: 12px !important;
    top: 50%;
    left: -25px;
    transform: translate(0%, -50%);
    // border: 1px solid #999;
    // border-radius: 3px;
    text-transform: uppercase;
    font-size: 8px;
    // padding: 0px 3px;
    background-color: #f2f2f2;
    .el-input__inner {
      height: 24px;
      line-height: 24px;
      padding-right: 0;
      padding-left: 5px;
    }
    .el-input__suffix {
      padding-right: 0;
      right: -3px;
      top: -3px;
      .el-input__icon {
        line-height: 16px !important;
      }
    }
  }
}

.bottom-search-block :deep {
  // bottom: -18px;
  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin: 6px 12px 6px 0px;
  }
}
.search-form:deep() {
  // update to align with el-input
  .el-select {
    margin-top: 1px;
  }

  // update to align with el-select
  .el-date-editor {
    margin-top: 1px;
  }
}

.bg-gray-color {
  background-color: $light-gray;
}
</style>
